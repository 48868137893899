import { ElementType, FC, ReactNode } from "react"
import { Article, ArticleImage, ArticleListType, ArticleSearchHitInfo, DataSupplier, EArticleModificationState, ListOptions, ModificationState, OE, PureListProps, WholesalerArticle } from "../article"
import { ManufacturerBase, Vehicle } from "../vehicle"
import { AvailabilityComponentProps, ErpInformation, ErpInformationRequestItem, ErpSystemConfig, TeccomErpInformation } from "../erp"
import { ArticleReferenceType } from "../enumerations"
import { Note } from "../notes"
import { ArticleQuantities, OeArticleQuantities, SupplierArticleQuantities, WholesalerArticleDto, WholesalerArticleQuantities, WholesalerPart } from "../basket"
import type { Overwrite } from "type-zoo"
import * as DMS from "../dms"
import { MessageType } from "../dms/shared"

export type ArticleActionArea = "CENTER_ICONS" | "CENTER_BUTTONS" | "RIGHT_ICONS" | "RIGHT_MENU"

export type ArticleAction = {
    id: string
    area: ArticleActionArea
    text: string
    tooltip?: ReactNode
    icon: string
    isHighlight?: boolean
    handler(): void
}

export type ListFilter = {
    id: number
    key?: string
    name?: string
    description?: string
    abbreviation?: string
    text?: string
    value?: string
    unit?: string
    group?: string
    query?: string
    priority?: number
    isTopPriority?: boolean
    articleCount?: number
    topPriorityArticleCount?: number
    isSelected?: boolean
    showOnTop?: boolean
    hasFittingSideFilters?: boolean
    valueKey?: string
    isSelectable?: boolean
}

export type AttributeValueParms = {
    id: number
    isSelected?: boolean
    isHighlighted?: boolean
    tooltip?: string
    label?: string
    value?: string
    displayValue?: string
    modificationState: ModificationState // For IMS
    isOptimized: boolean // For DOM
    alternatives: Array<string> // For DOM
}

// TODO: mehr als ein Attribut erlauben / Attribute zusammenfassen
export type ArticleAttributeParams = {
    id: number
    key: string
    label?: string
    values?: AttributeValueParms[]
    isRecommended?: boolean
    isSharedAttribute?: boolean
    isArticleAttribute?: boolean
    onClickAttribute?(parms: ArticleAttributeParams): void
    // Wenn mehr als eine Attribut, dann kann die Option genutzt werden
    // um diese filter als Oder verknüpft zu betrachten, standard "OR"
    filterOption?: "AND" | "OR"
}

export type OeInformationNumber = OE.OeNumber & {
    parts: Array<OE.OePart>
    isMainArticle: boolean
}

export type ExtendedAssortment = {
    /** Enabled by the user. */
    enabled: boolean
    /** Enabled by selecting a non priority product group or data supplier filter. */
    forced: boolean
}

export type OeInformation = {
    manufacturer: ManufacturerBase
    oeArticles: Array<OeInformationNumber>
}

export type OeReplacementManufacturer = {
    id: number
    name: string
    thumbnail?: string
    replacementNumbers: Array<string>
}

export enum SearchFilterTypes {
    SupplierArticleNo = 1, /// Also known as EArtNr, Einspeiserartikelnummer
    OeReference = 2, /// Also known as OE Referenz
    UtilityNo = 4, /// Also known as Gebrauchsnummer
    ReplacementNo = 8, /// Also known as Ersatznummer
    Manufacturer = 15, /// Also known as Hersteller // combination of all above
    EuropeanArticleNumber = 16, /// Also known as EAN, EANNr, Europäische Artikelnummer
    TradeReference_KART = 32,
    TradeReference_KARTREF = 64,
    TradeReference = 96, /// Also known as Handelsreferenz // combination of the two above
    SupplierArticleNoAndTradeReference = 97,
    OeAndTradeReference = 98, // combination of OeReference + TradeReference
    All = 2047,
}

export type SearchFilter = {
    filterType: SearchFilterTypes
    count: number
}

export type TradeReferenceNumber = {
    erpSystemId: number
    tradeReferenceId: number
    traderArticleNumber: string
}

export type ArticleErpKey = {
    internalId: number | string
    quantity: number
    erpSystemId: number
}

export type ComparableArticleErpKey = ArticleErpKey & {
    id: string
}

type QueuedArticleErpInfo = {
    state: "queued"
}

type LoadingArticleErpInfo = {
    state: "loading"
}

export type SuccessArticleErpInfo = {
    state: "success"
    response: ErpInformation
    erpSystem?: ErpSystemConfig

    /*
     * The sort number for this article returned from the ERP system.
     *
     * Copied from the response because when the quantity of this article was changed and another ERP request was made,
     * the sort number might have changed but the original one should still be used for sorting.
     */
    sortNumber: number | undefined
}

type ErrorArticleErpInfo = {
    state: "error"
    errorText: string
}

export type ArticleErpInfo = {
    /**
     * A unique identifier for this request.
     *
     * It's not necessary two have two items in a request which have the same key.
     * Generate it with `createArticleErpInfoKey`.
     */
    key: ComparableArticleErpKey
    state: unknown
    request: ErpInformationRequestItem
    erpSystem?: ErpSystemConfig
    listType?: ArticleListType
} & (QueuedArticleErpInfo | LoadingArticleErpInfo | SuccessArticleErpInfo | ErrorArticleErpInfo)

export type ArticleErpInfos = {
    default?: ArticleErpInfo
    alternative?: ArticleErpInfo
}

export type ArticleOptions = ListOptions & {
    compactView?: boolean
    hasSuggestedQuantity?: boolean
    // Article will be shown as disabled, if this reason is filled
    disableReason?: string
}

export type ExtendedDeliveryInfo = {
    showExtendedDeliveryInfo?: boolean
    isLoading?: boolean
    info?: TeccomErpInformation
    load?(): void
}

export type ArticleAttributesParams = {
    // Top ArtikelAttribute
    topArticleAttributes?: ArticleAttributeParams[]
    // additionalAttributes
    additionalAttributes?: ArticleAttributeParams[]
}

export type SupplierParms = {
    supplier: DataSupplier
    supplierLogo?: string
    imgLoading?: boolean
    tagHeadline?: string
    selected?: boolean
}

export type ArticleNumbersParms = {
    hideDealerPartNumber?: boolean
    traderArticleNo?: string
    oeArticleNo?: string
    supplierArticleNo?: string
    copyButtonTrader?: boolean
    copyButtonSupplier?: boolean
}

export type ArticleImageParms = {
    thumbnailUrl: string
    thumbnailDescription?: string
    thumbnailFallbackUrl?: string
    articleImages?: ArticleImage[]
    startIndex?: number
    enableLargeView: boolean
    customThumbnailComponent?: React.ReactNode
    loading?: boolean
    onClick?(): void
    onLoadImages?(): void
}

export type ArticleDescriptionParms = {
    position: "top" | "bottom"
    traderDescriptopnPosition: "top" | "bottom"
    additionalDescriptions?: string[]
    missingVehicleConnectionTitle?: string
    description?: string
}

export type ArticlePriceParms = {
    title: string
    price: string
    priceColor?: string
    priceInfo?: string
    order: number
    modifier: "none" | "strikethrough"
    size?: "small" | "default" | "big"
    hideLable?: boolean
    subline?: string
    tooltip?: JSX.Element | null
}

export type ArticleReferenceParms = {
    label: string
    referenceType: ArticleReferenceType
    modificationState?: ModificationState
    description?: string
    manufacturer?: string
}

export type AdditionalInformationLink = {
    icon?: string
    href: string
    name: string
}

export type ArticleNotification = {
    variant: "success" | "warning" | "info" | "error"
    message: string
    link?: string
}

export type WatchListItem = { id: string; name: string; selected: boolean }
export type WatchListParams = {
    isInWatchList: boolean | undefined
    watchLists: WatchListItem[]
    onToggleWatchList(compilationId: string): void
    onCreateWatchList(name: string): void
    isToggling: boolean
    isCreating: boolean
    isLoading: boolean
}

export type ArticleNotes = {
    notes: Note[]
    isLoading: boolean
    visible: boolean
    handleSave(note: Note): Promise<void>
    handleDelete(noteId: string): Promise<void>
    toggle(): void
    hide(): void
}

export type RecommendedArticles = {
    enabled: boolean
    isLoading: boolean
    loadRecommendedArticles(): Promise<void>
    loadNextRecommendedArticle: (newArticle: Article) => Promise<void>
    onClose(): void
    ListComponent: FC<PureListProps>
}

export type WatchList = {
    watchLists: WatchListItem[] | undefined
    isInWatchList?: boolean
    createWatchList: (name: string) => void
    createOrAddWatchlist: (name: string) => void
    toggleWatchList: (listId: string) => void
    isLoading: boolean
    isToggling: boolean
    isCreating: boolean
    singleWatchlist: boolean
}

export type ErpInfoData = {
    erpSystem: ErpSystemConfig
    traderArticleNumber: string
    erpInformation?: ErpInformation
    isLoading?: boolean
}

export type TraderErpInfosData = {
    loadTraderErpInfos(): void
    traderErpInfos: ErpInfoData[]
    isLoading: boolean
}

type SearchHitInfo = Overwrite<
    ArticleSearchHitInfo,
    {
        searchFilter: string
    }
>

type FoundByParms = {
    hitInfos?: SearchHitInfo[]
    isLoading?: boolean
    load(): Promise<void>
}

type ArticleSelectionParms = {
    selectedArticles: Article[]
    toggleSelectedArticle(article: Article): void
    deselectArticle(articleUniqueId: string): void
    deselectAll(): void
    isArticleSelected(article: Article): boolean
}

export type BonusSystemParams = {
    id?: number
    name?: string
    imageUrl?: string
    value?: number
}

export type DmsArticleInfo = {
    stockInfoItems?: Array<{ label?: string, value?: string }>
    voucherInfoItems?: Array<{ label?: string, value?: string }>
    stockItem?: DMS.StockItem
    permittedOperations?: Array<MessageType>
    handleVoucherClick: (voucher: DMS.VoucherReference) => void
}

export type DisplayArticle = {
    // Artikelnummer
    numbers: ArticleNumbersParms
    // Artikelbilder
    image: ArticleImageParms
    // Hersteller infos
    supplier: SupplierParms
    // Bonussystem
    bonus: BonusSystemParams
    // Artikelbeschreibung
    description: ArticleDescriptionParms
    // Fahrzeugspezifische Attribute
    attributes?: ArticleAttributesParams[]
    // Referenzes
    references?: ArticleReferenceParms[]
    // Artikel zusatzinformationen
    additionalInformation?: {
        references?: ArticleReferenceParms[]
        // generalInformation is shown as a block above the additionalInformation
        generalInformation?: [string, string[]][]
        headInformation?: string[]
        textInformation?: [string, string[]][]
        links?: AdditionalInformationLink[]
    }
    // Merklisten / Compilations
    watchList?: WatchListParams
    // Artikelnotizen
    notes?: ArticleNotes
    // OE Price
    oePrice?: ArticlePriceParms[]
    // IMS Modification
    modificationState?: EArticleModificationState
    // Shared top attributes (only used for articles which have data for multiple vehicles)
    sharedAttributes?: ArticleAttributeParams[]
}

/** Contains the current state of the articleItem. */
type GenericArticleItemState<TArticle = unknown> = {
    type: "default" | "OE" | "OeInformation"
    isVehicleDependent: boolean
    quantity: number
    originalQuantity: number
    basketQuantity?: SupplierArticleQuantities | OeArticleQuantities
    article: TArticle
    vehicle?: Vehicle
    showReplaceButton?: boolean
    articleErpInfos?: ArticleErpInfos

    handleChangeQuantity: (value: number, loadErpInfo?: boolean) => void
    handleAddToBasket(internalQuantity?: number): Promise<void>
    handleRemoveFromBasket(): Promise<void>

    AvailabilityComponent: ElementType<AvailabilityComponentProps>
}

/** Contains the current state of the default Article Item variant. */
export type DefaultArticleItemState = Overwrite<
    GenericArticleItemState<Article>,
    {
        type: "default"

        options: ArticleOptions
        displayArticle: DisplayArticle
        articleActions: ArticleAction[]

        showImageViewer: boolean
        expanded: boolean
        traderSelection?: boolean

        notes: ArticleNotes
        tradeReferences?: TradeReferenceNumber[]
        traderErpInfos?: TraderErpInfosData
        notifications?: ArticleNotification[]

        basketQuantity?: SupplierArticleQuantities

        watchList: WatchList
        positionChangeEArtNrHArtNr?: boolean
        foundBy?: FoundByParms
        selection: ArticleSelectionParms
        extendedDeliveryInfo: ExtendedDeliveryInfo

        imsIcon?: string

        articleImages: ArticleImages
        dmsArticleInfo: DmsArticleInfo
        alreadyOrdered: boolean
        recommendedArticles?: RecommendedArticles

        handleClickImage(): void
        handleCloseImage(): void
        handleClickDetails(subPage?: string, scrollTo?: string): void
        handleClickReference(reference: ArticleReferenceParms): void
        handleClickAttribute?(is: number, value?: string): void
        handleAddToBasket(quantity?: number, erpInformation?: ErpInformation, erpSystemId?: number): Promise<unknown>
        handleAddToCostEstimation(quantity?: number, erpInformation?: ErpInformation, erpSystemId?: number): Promise<unknown>
        handleRemoveFromBasket(itemId?: string[]): Promise<unknown>
        handleReplaceArticle?(): void
        handleCopyArticleNumber(number: string): void
        toggleExpand(): void
        toggleTraderSelection?(): void
        toggleSupplierFilter?(exclusive?: boolean): void
    }
>

type ArticleImages = {
    data: ArticleImage[] | undefined
    isLoading: boolean
    setEnabled: (value: boolean) => void
}

export type WholesalerArticleItemState = Overwrite<
    GenericArticleItemState<OE.OePart>,
    {
        type: "WholesalerArticle"
        article: WholesalerArticle
        basketQuantity?: ArticleQuantities<WholesalerArticleDto>
        options: ArticleOptions
    }
>

export type OeArticleItemState = Overwrite<
    GenericArticleItemState<OE.OePart>,
    {
        type: "OE"
        basketQuantity?: OeArticleQuantities
        thumbnailUrl: string
        manufacturerThumbnail?: string
        oeArticleNo: string
        wholesalerArticleNumber: string
        options: ArticleOptions
        handleReplaceArticle(): void
    }
>

export type OeInformationItemState = Overwrite<
    Omit<GenericArticleItemState<OE.OePart>, "article">,
    {
        type: "oeInformation"
        showAddToBasket: boolean
        description: string
        oeArticleNo: string
        thumbnailUrl: string
        selectedPart: OE.OePart
        parts: OE.OePart[]
        attributes: ArticleAttributesParams[]
        basketQuantity?: OeArticleQuantities
        oePrices: ArticlePriceParms[]
        options: Overwrite<ArticleOptions, {showDataSupplierLogos?: boolean}>
        handlePartChange(id: string): void
    }
>

export type WholesalerArticleNumbersItemState =
    Overwrite<
        GenericArticleItemState<Article>,
        {
            type: "wholesalerArticleNos"
            thumbnailUrl: string
            wholesalerArticleNumber: string
            options: ArticleOptions
            basketQuantity: WholesalerArticleQuantities | undefined
            extendedDeliveryInfo: ExtendedDeliveryInfo
        }
    >


export type ArticleItemState = DefaultArticleItemState | OeArticleItemState | OeInformationItemState | WholesalerArticleNumbersItemState | WholesalerArticleItemState
